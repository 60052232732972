import React, { useEffect, useMemo, useState } from 'react'
import './trackorder.css'
import { useLocation } from 'react-router-dom'
import Toast from '../../Toast'
import axios from '../../axios'
import Loader from '../../Loader'

const TrackOrder = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [tractData, setTractData] = useState(null)
  const location = useLocation()
 const data = location?.state?.data?.enquery
 console.log("data.....",data.tracking_id );
 document.title="Track-Order"

 const newdate =useMemo(()=>{
  var date = new Date(data?.created_at)
  var estimatedays =  new Date();
  estimatedays.setDate(date.getDate()+10)
  return estimatedays
  

 },[data])
 const handleRefreshClick = () => {
  window.location.reload(); // This will refresh the page
};


const trackorder = async () => {
  
  try {
    setIsLoading(true);
    const response = await axios({
      method: "get",
      url: `/get_tracking_detail?tracking_id=${data?.tracking_id}`,
    });

    if (response.status === 200) {
      const data = response.data;
      setTractData(data.enquery)
      // navigate("/track-order", { state: { data } });
    }
  } catch (err) {
    const error = err.response.data;
    Toast(error.message);
  } finally {
    setIsLoading(false);
  }
};

useEffect(()=>{
  trackorder()
},[])

if(tractData){
  console.log("tractdata...",tractData)
}

  return (
    <>
    {isLoading && <Loader/>}
    <div className="section-padding mx-auto" style={{maxWidth:2000 ,}}>
        {/* <h2 className="section-heading text-center"> Track your Package </h2> */}
        {/* <p > expected delivery : <span style={{color:'grey'}}> {newdate.toDateString()}</span></p> */}
        {/* <p style={{color:'grey'}}> Arrived by
         fedx </p> */}

        
       <div className="conntainer-fluid">
        <div className="row">
          <div className="col-12 d-flex flex-column flex-md-row justify-content-center tract-order-heading">
          <h2 className="section-heading text-center mt-4 mt-md-1"> Track your Package </h2>
          <button className='btn btn-outline-primary btn-sm tractorder-btn d-none d-md-block' onClick={()=>{handleRefreshClick(); trackorder()}}>Refresh</button>
            <div className='d-flex justify-content-center d-md-none'>
            <button className='btn btn-outline-primary btn-sm d-block d-md-none' onClick={()=>{handleRefreshClick(); trackorder()}}>Refresh</button>
            </div>
          
         
          
          </div>
        </div>
       </div>
    <div className="d-flex shipcontainer " style={{gridGap:40}} >
    <div style={{padding:20,}} className="shippinginfo">
        <h4 className='section-subheading mb-4'> Shipping Information</h4>
        
        <div style={{display:'flex'}}>
        <p style={{width:100}}>Delivery </p>
        <p>fedx delivery available  </p>
        </div>

        <div style={{display:'flex'}}>
        <p style={{width:100}}>From : </p>
        <p>{tractData?.from_location} </p>
        </div>

        <div style={{display:'flex'}}>
        <p style={{width:100}}>To :</p>
        <p>{tractData?.to_location} </p>
        </div>

        <div style={{display:'flex'}}>
        <p style={{width:100}}>Tracking #  </p>
        <p>{tractData?.tracking_id}</p>
        </div>

        <div style={{display:'flex'}}>
        <p style={{width:100}}>Address </p>
        <p>{tractData?.to_floor} , {tractData?.to_location}</p>
        </div>

        <div style={{display:'flex'}}>
        <p style={{width:100}}>Order #</p>
        <p>COD </p>
        </div>
    </div>
    {/* <div className="tracking border">

    {data?.tracking_status?.map((element, index) =>{

      return <div className="">
      <div className=''>
    <div style={{backgroundColor:'#088FD8',width:20,height:20,borderRadius:'50%'}}></div>
    <div className="tracking-line"></div>
 
    </div>
    <p> {element.status}<br/>{new Date(element?.created_at).toDateString()}</p>
    </div>
    })}
    
    <div>
    <div style={{backgroundColor:'#088FD8',width:20,height:20,borderRadius:'50%'}}></div>
    <p> complete  <br/>expected</p>
    </div>
    

        </div> */}
        {/* right card */}
    <div style={{padding:20,}} className="shippinginfo">
        <h4 className='section-subheading mb-4'> Tracking History </h4>

  {/* dfg */}
  <div className="tracking">

{tractData?.tracking_status?.map((element, index) =>{
{/* {arr?.map((element, index) =>{ */}

  return <div className="">
  <div className=''>
<div style={{backgroundColor:'#088FD8',width:20,height:20,borderRadius:'50%'}}></div>
{tractData?.tracking_status?.length===index+1?"":<div className="tracking-line"></div>}
{/* <div className="tracking-line"></div> */}

</div>
<div className="container-fluid">
<div className="row">
  <div className="col-12 col-md-7">
    <p className='tracking-text'>{element.status}</p>
  </div>
  <div className="col-12 col-md-5">
    <p className='text-start text-md-end tracking-date'>{new Date(element?.created_at).toDateString()}</p>
  </div>
</div>
</div>
{/* <p> {element.status}<br/>{new Date(element?.created_at).toDateString()}</p> */}
</div>
})}

{/* <div>
<div style={{backgroundColor:'#088FD8',width:20,height:20,borderRadius:'50%'}}></div>
<p> complete  <br/>expected</p>
</div> */}


    </div>
  {/* end */}


        
        {/* {data?.tracking_status?.map((elemenet)=>{

          return  <div style={{display:'flex'}}>
        <p style={{width:100}}>{new Date(elemenet?.created_at).toLocaleTimeString()} </p>
        <p>{elemenet?.status}<br/><span style={{fontSize:12}}> {new Date(elemenet?.created_at).toLocaleDateString()}</span> </p>
        
        </div>
        })} */}
       

        
    </div>
    </div>

    </div>

    </>
  )
}

export default TrackOrder