import React, { useState } from "react";
import axios from "../../../../axios";
import validator from "validator";
import Toast from "../../../../Toast";

function ContactForm() {
  const [isLoading, setIsLoading] = useState(false);
  const [contact, setContact] = useState({
    name: "",
    mobile: "",
    email: "",
    subject: "",
    message: "",
    city: "",
    service: "",
  });

  const handleChange = (e) => {
    setContact({ ...contact, [e.target.name]: e.target.value });
  };

  // console.log("contact", contact);
  const Contact_form = async (e) => {
    const { name, mobile, email, subject, message } = contact;
    e.preventDefault();

    if (!email || !message || !mobile) return Toast("please fill properly");
    if (!validator.isEmail(email)) return Toast("email is not valid");
    if (!validator.isMobilePhone(mobile)) return Toast("mobile is not valid");

    try {
      setIsLoading(true);
      const response = await axios({
        method: "post",
        url: "/add_contact",
        data: {
          name,
          mobile,
          email,
          subject,
          message,
        },
      });

      if (response.status === 200) {
        const data = response.data;

        Toast(data.message, response.status);

        contact.name = "";
        contact.email = "";
        contact.message = "";
        contact.mobile = "";
        contact.subject = "";
        contact.city = "";
        contact.service = "";
      }
    } catch (err) {
      const error = err.response.data;
      Toast(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container-fluid my-4 my-lg-2 d-flex justify-content-center align-items-center">
      <div className=" d-flex justify-content-center align-items-center ms-lg-4 form-container rounded p-4 p-lg-2">
        <form className="p-0 me-lg-3 form-width mt-lg-4">
          <div class="mb-3 py-1">
            <input
              type="text"
              class="form-control"
              value={contact.name}
              placeholder="Your Name"
              name="name"
              id="name"
              onChange={handleChange}
            />
          </div>
          <div class="mb-3 py-1 form-group">
            <input
              type="text"
              name="email"
              value={contact.email}
              id="email"
              placeholder="Your Email"
              className="form-control"
              onChange={handleChange}
            />
          </div>
          <div class="mb-3 py-1">
            <input
              type="number"
              class="form-control"
              value={contact.mobile}
              placeholder="Your Phone No."
              name="mobile"
              id="mobile"
              onChange={handleChange}
            />
          </div>
          <div class="mb-3 py-1">
            <input
              type="text"
              class="form-control"
              placeholder="From : Enter City Name"
              value={contact.subject}
              name="subject"
              id="subject"
              onChange={handleChange}
            />
          </div>

          <div className="mb-3 py-1">
            <input
              class="form-control"
              placeholder="To : Enter City Name"
              value={contact.message}
              name="message"
              id="message"
              onChange={handleChange}
            />
          </div>

          <div className="mb-3 py-1">
            <input
              type="date"
              class="form-control"
              placeholder=" Shifting Date"
              value={contact.city}
              name="city"
              id="city"
              onChange={handleChange}
            />
          </div>

          <div className="mb-3 py-1">
            <input
              class="form-control"
              placeholder="Message"
              value={contact.service}
              name="service"
              id="service"
              onChange={handleChange}
            />
          </div>

          <button
            class="btn btn-primary cuntact-submit-btn w-100"
            onClick={Contact_form}
          >
            Send Message
          </button>
        </form>
      </div>
    </div>
  );
}

export default ContactForm;
