import React from 'react'
import './digital.css'

const DigitalContact = () => {
  document.title="globle-Network"
  return (
    <>
    <div className="  " style={{width:'100%',backgroundColor:'transparent'}}>
        {/* <img src='images/earth.png' style={{width:200,height:200}} alt='igm' /> */}
        <div className=" mb-5 pt-3 mt-3 text-center" style={{height:'200px'}}  >
        <h3 className='fw-bold'>Budget logistics Mover & Packers</h3>
        <p>No matter where… we ship them there!</p>
        </div>
       
    </div>
<div className="globle-contact">






</div>

<div className="globle-table section-padding">
    <table>
    <tr>
    <th>West Europe</th>
    <th>Middle East </th>
    <th>Asia Pacific</th>
  </tr>
  <tr>
    <td>France</td>
    <td>United Arab Emirates</td>
    <td>Australia</td>
  </tr>
  <tr>
    <td>Germany </td>
    <td>Turkey</td>
    <td>Indonesia</td>
  </tr>
  <tr>
    <td>Neitherland</td>
    <td>Saudi Arabia</td>
    <td>New Zealand</td>
  </tr>
  <tr>
    <td>Ireland</td>
    <td></td>
    <td>Singapore</td>
  </tr>
  <tr>
    <td></td>
    <td></td>
    <td>China</td>
  </tr>
  <tr>
    <td></td>
    <td></td>
    <td>Thailand</td>
  </tr>
  
    </table>
</div>

    </>
  )
}

export default DigitalContact