import { customer,quality,premium,rating,team,rectangle,group } from '../../assest'

export const ImgArr = [
    {
        id:1,
        Img:customer,
        Text:"Personalized Service"

    },
    {
        id:2,
        Img:rating,
        Text:"Experience"
    },
    {
        id:3,
        Img:quality,
        Text:"Quality Assurance"
    },
    {
        id:4,
        Img:team,
        Text:"Worldwide Network"
    },
    {
        id:5,
        Img:premium,
        Text:"Premium Service"
    },


]