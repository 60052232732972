import React, { useRef } from 'react'
import { Banner,About,Services,Uniqueselling,Growing,Slider,Contact, FlatRent } from './Exportfile'
import Contacts from '../homepage/contact/changeContact/Contact'
import Fade from 'react-reveal/Fade';
import { Helmet } from 'react-helmet';
import OurCustomers from './ourcustomer/carousel/OurCustomers';
import OurBlog from './ourblog/OurBlog';
import OurTopCity from './ourtopcity/OurTopCity';
import Banners from './banner/Banners';
import GrowingText from './growing/GrowingText';
import ContactModel from './globalPopup/ContactModel';
import YoutubeSection from './youtubepage/YoutubeSection';


const Homepage = () => {
  const contactSectionRef = useRef(null);

  const scrollToContactSection = () => {
    // Use the scrollIntoView method to scroll to the contact section
    contactSectionRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };
  return (
    <>
    <Helmet>
                
                <title>Budget Logistics and Packers</title>
                <meta
      name="description"
      content="Budget Logistics and Packers is a leading packers and movers company in India. We provide relocation solutions in India and Globally as well"
    />
    <meta name="keywords" content="packers and movers, relocation solution, packers and movers near me, office relocation , home relocation , warehousing storage , international relocation "></meta>
            </Helmet>
            <div className='' style={{position:'relative'}}>
                <button onClick={scrollToContactSection} className="btn btn-sm" style={{top:'330px', transform:'rotate(270deg)', color:'white', right:'-38px', position:'fixed', zIndex:'999', backgroundColor:'rgb(30, 89, 203)'}}>Quick Contact</button>
           
            <Banners/>
    {/* <Banner title={"Engage with the Largest Packer Community and Network"} /> */}
    
    
    <About/>
    
    <Services/>
    
    <Uniqueselling/>
    <Growing/>
     <OurCustomers/>
     <OurBlog/>
     <YoutubeSection/>
     <OurTopCity/>

    <Slider/>

    {/* <Ourcustomer/> */}
  
     <Contacts contactSectionRef={contactSectionRef} />
      <FlatRent title="Packers & Movers"/>
      </div>
    {/* <Contact/> */}
    
    {/* <FlatRent title={"Packer And Movers"}/> */}
    {/* <GrowingText/> */}
    {/* <Growing/> */}
    </>
  )
}

export default Homepage