import './contact.css';
import ContactForm from './utils/ContactForm';
import contactArr from './utils/ContactLogoArr';
function Contact({contactSectionRef}) {
  return (
     <div className="container-fluid  py-4" ref={contactSectionRef}>
        <div className="container py-2">
            <div className="row" >
                <div className="col-12 col-lg-6 ">
                  <div className="row">
                    <div className="col-12 mt-lg-5">
                    <h3  className='first-heading text-center text-lg-start py-1 py-lg-2 '>Contact Us</h3>
                    <h4 className='fs-4 fw-bold text-center text-lg-start'>Feel Free To Contact Us </h4>
                    <p className=' w-lg-100 change-font text-center text-lg-start'>You can reach us by phone at +91 - 9916693666 or by email at info@budgetlogisticspackers.com . Alternatively, you can fill out the contact form below and we will get back to you as soon as possible.</p>
                    </div>
                  </div>
                    <div className="row">
                        <div className="col-12 col-lg-12" >
                             <div className=" contact-log ">
                             <div className="row ">
                                


                             {
                                contactArr.map((val, idx)=>(
                                    
                                        <div key={idx+1} className="col-12 col-lg-12">
                                        <div className=" d-flex flex-column align-items-center flex-lg-row ">
                                       <div className="img-contact-container  ms-lg-0 me-3 my-3">
                                        <img src={val.contactLogo} alt="img" className='img-fluid text-center' />
                                       </div>
                                      <div className=" d-flex flex-column justify-content-center">
                                       <h3 className='fs-6 fw-bold text-center text-lg-start'>{val.headings}</h3>
                                        <h3 className=' fw-lighter text-center text-lg-start contactsub-heading'>{val.details}</h3>
                                      </div>
                                 </div>
                                   </div>                        
                                ))
                             }
                          </div>
                                 
                             </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-6 d-flex justify-content-center align-items-center" >
                    <ContactForm/>
                </div>
            </div>
        </div>
     </div>
  )
}

export default Contact