import React from 'react'
import './youtubesection.css'
function YoutubeSection() {
  return (
       <div className="container-fluid py-4 mb-2">
        <div className="container">
            <div className="row pb-3">
                <div className="col-12">
                    <h3 className='text-center py-3 first-heading'>OUR VIDEOS</h3>
                </div>
                <div className="col-12 col-md-4 mb-3">
                <iframe width="100%" height="200" src="https://www.youtube.com/embed/1Y13nnTlUKA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <div className="col-12 col-md-4">
                <iframe width="100%" height="200" src="https://www.youtube.com/embed/1Y13nnTlUKA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <div className="col-12 col-md-4">
                <iframe width="100%" height="200" src="https://www.youtube.com/embed/1Y13nnTlUKA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                
            </div>
        </div>
       </div>
  )
}

export default YoutubeSection