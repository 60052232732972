import home from '../../../assest/contactA.png'
import call from '../../../assest/contactB.png'
import message from '../../../assest/contactC.png'

const contactArr = [
    {
       contactLogo:home,
       headings:'Our Location',
       details:'Gurgaon, Haryana'
    },
    {
        contactLogo:call,
        headings:'Phone Number',
        details:'+91 99-166-93-666'
     },
     {
        contactLogo:message,
        headings:'Email Address',
        details:'support@budgetlogisticspacker.com'
     },

   
]
export default contactArr;