import { useEffect, useState } from 'react';
import Loader from '../../Loader'
import './ourblog.css';

//import bloggArr from './utils/BlogArr'
import axios from '../../axios';
import Toast from '../../Toast';
import { Link, useNavigate } from 'react-router-dom';

function OurBlog() {
    const [blogContent, setBlogContent] = useState([]);
    const [isLoading,setIsLoading] = useState(false)
      const navigate = useNavigate()  

    const getOurBlogg =async () =>
    {
        try{
            setIsLoading(true)
            const response= await axios({
              method: "get",
             url:`/get_blogs`,
              
              } )
             
             if(response.status===200){
              let data = response?.data?.blogs.reverse();
                 data.length = 3
              setBlogContent(data)
              // Toast(data.message,response.status)
              
              
             }
            }
           catch(err){
            const error = err.response.data
            Toast(error.message);
            
         
         
           }
           finally{
            setIsLoading(false)
           }
         
    
    }
    useEffect(()=>{
        
        getOurBlogg()
    },[])

  //  console.log("data", blogContent);

  return (
      <div className="container-fluid">
          <div className="container py-4">
            <div className="row">
                <div className="col-12">
                    <h3 className='text-center'>Our Latest Blogs</h3>
                </div>
            </div>
            <div className="row pb-3  g-lg-3">
            {
                    blogContent.map((val)=>(
                <div className="col-12 col-lg-4 p-2" key={val.id}>
                    <Link to={"/blog/"+val?.slug} className=" link-card rounded d-flex flex-column align-items-center justify-content-start" >
                    <div className=''>
                        <img src={val?.image} alt="" className='img-fluid rounded' style={{height:'auto', width:'100%'}}   />
                        
                   
                    </div>
                    <h4 className='pt-2 px-2 pb-lg-1 blog-heading text-uppercase subb-font text-center '>{val?.heading.slice(0, 30)}...</h4>
                    <div className='text-center blog-text px-2 pb-3' dangerouslySetInnerHTML={{__html: `${val?.content.slice(0, 70)}...`}} />

                    </Link>
                </div>
                    ))}
            </div>
            <div className="row pb-3  g-lg-3">
                   {/* {
                    blogContent.map((val)=>(
                    <div key={val.id} className="col-12 col-lg-4">
                        <Link to={"/blog/"+val?.id} key={val.id} className=''>
                        
                    <div className="row p-2 p-lg-3 gx-3 ">
                        <div className="col-12 p-0 m-0">
                            <div className=''>
                                <img src={val?.image} alt="" className='img-fluid'    />
                            </div>
                        </div>
                        <div className="col-12 bg-change">
                           
                            <h4 className='pt-2 pb-lg-1 blog-heading text-uppercase subb-font text-center '>{val?.heading}</h4>
                        </div>
                        <div className="col-12 bg-change">
                            <div className='text-center blog-text pb-4' dangerouslySetInnerHTML={{__html: `${val?.content.slice(0, 70)}...`}} />
                        </div>
                    </div>
                    </Link>
                </div>
                    ))
                   } */}
                <div className="col-12 d-flex justify-content-center align-items-center">
                    <button onClick={()=>navigate('/blogs')} className='blogg-btn'>View All Blogs</button>
                </div>
            </div>
          </div>
      </div>
  )
}

export default OurBlog