
import React,{useEffect,useState} from 'react'
import { useLocation, } from 'react-router-dom'
import { AuthContext } from '../../AuthProvider'
import axios from '../../axios'
import Contact_Form from '../servicepage/contact-form/Contact_Form'
import Question from '../servicepage/question/Question'
import './FlatforRent.css'
import Loader from '../../Loader'
import { Helmet } from 'react-helmet'
import Toast from '../../Toast'
import { FlatRent } from '../../homepage/Exportfile'
import Banners from '../../homepage/banner/Banners'


const FlatforRent = ({id}) => {
  const [citydata,setcityData] = useState([])
  const [isLoading,setIsLoading] = useState(false)
  
  const pathname = useLocation()
 // console.log(id) 

  const cityfun =async () =>
  {
      try{
          setIsLoading(true)
          const response= await axios({
            method: "get",
           url:`/get_city_detail?city_id=${id}`,
            
            } )
           
           if(response.status===200){
            const data = response.data;
           // console.log("data...",data);
            setcityData(data?.cities)
            // Toast(data.message,response.status)
            
            
           }
          }
         catch(err){
          const error = err.response.data
          Toast(error.message);
          
       
       
         }
         finally{
          setIsLoading(false)
         }
       
  
  }

const getstar =(rating) => {

  var star =[]
  for(var i=0; i<rating;i++){
      star.push(<i class="bi bi-star-fill"></i>)
  }
  return star
}



  useEffect(() => {
    cityfun()

  },[pathname])



  return (
     <>
      <Helmet>
                
                 <title>{`packer-and-movers-in-${id}`}</title>
                
     <meta name="keywords" content={`packers and movers in ${id}, 
     best packers and movers in ${id}, packers and movers near me, packers and movers, movers and packers  `} />
    
    <meta name="description" content={`packers and movers in ${id}, 
     Your Trusted Relocation Partners. We specialize in seamless and stress-free moving solutions for homes and businesses. Our dedicated team of experts handles every aspect of your move, from carefully packing and securing your belongings to swift and secure transportation to your new destination. With years of experience, state-of-the-art equipment, and a commitment to excellence, we ensure a smooth transition to your new space. Trust us to handle your move with care, efficiency, and professionalism.
      `} />
             </Helmet> 
    {!isLoading?
    <div>
  <Banners  title={citydata?.banner_title} banner={citydata?.banner}/>
    <div class="container section-padding">
    <p id="description-text"   dangerouslySetInnerHTML={{__html: `${citydata?.content}`}}/>
    </div>

    <Question QuesArr={citydata?.faq}/>
      <FlatRent  title={"Packer And Movers"}/>
    </div>
    :<Loader />}
    </>
  )
}

export default FlatforRent