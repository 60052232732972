import React, { useState } from 'react'
import './globalmodel.css';
import axios from '../../axios'
import validator from 'validator'
import Toast from '../../Toast'

function ContactFormModel({ sendDataToParent }) {

    const [isLoading,setIsLoading] = useState(false)
    const [contact, setContact] = useState({
        name:"",
        mobile:"",
        email:"",
        subject:"",
        message:"",
        city:"",
        service:""
    })
 
    const handleChange = (e) => {
 
        setContact({...contact,[e.target.name]:e.target.value})
       
    }
 
    // console.log("contact", contact);
    const Contact_form = async(e)=>{
        const {name, mobile, email, subject, message, city, service} = contact
        e.preventDefault()
      
         if( !email || !message || !mobile ) return Toast("please fill properly")
         if( !validator.isEmail(email)) return Toast("email is not valid")
         if( !validator.isMobilePhone(mobile)) return Toast("mobile is not valid")
       
    
         try{
          setIsLoading(true)
          const response= await axios({
            method: "post",
           url:'/add_contact',
            data:{
              name,mobile,email,subject,message
            },
    
           })
           
           
           if(response.status===200){
            const data = response.data;
           
            Toast(data.message,response.status)
            sendDataToParent(data);
             contact.name="";
             contact.email="";
             contact.message="";
             contact.mobile="";
             contact.subject="";
             contact.city="";
             contact.service=""
           
           }
           
         }
         catch(err){
          const error = err.response.data
          Toast(error.message)
    
         }
         finally{
          setIsLoading(false)
         }
        
 
      }
 


  return (
    <>
        <form className='p-4 mt-lg-1 globalModelForm'>
                <div class="mb-4">
                  <input type="text" class="form-control bg-input p-lg-2" value={contact.name} placeholder='Your Name' name="name" id="name" onChange={handleChange} />
               </div>
               <div class="mb-4">
               <input type="text" name='email' value={contact.email} id="email" placeholder='Your Email' className="form-control p-lg-2 bg-input"  onChange={handleChange}/>
                  
               </div> 
               <div class="mb-4">
                  <input type="number" class="form-control bg-input p-lg-2" value={contact.mobile} placeholder='Your Phone No.' name="mobile" id="mobile" onChange={handleChange} />
               </div> 
               <div class="mb-4">
                  <input type="text"  class="form-control bg-input p-lg-2" placeholder='Shifting From' value={contact.subject} name="subject" id="subject" onChange={handleChange} />
               </div> 
               <div className="mb-4">
               <input type='text' class="form-control bg-input p-lg-2" placeholder="Shifting To" value={contact.message} name='message' id='message' onChange={handleChange} />
               </div> 

               <div className="mb-4">
               <input type='date' class="form-control bg-input p-lg-2" placeholder="Shifting" value={contact.city} name='city' id='city' onChange={handleChange} />
               </div> 
               <div className="mb-4">
               <input type='text' class="form-control bg-input p-lg-2" placeholder="Message" value={contact.service} name='service' id='service' onChange={handleChange} />
               </div> 

               
                <button class="btn p-lg-2 btn-primary cuntact-submit-btn w-100" onClick={Contact_form}>Send Message</button>
             </form>
    </>
  )
}

export default ContactFormModel