import React from 'react'
import './banners.css'
import { useNavigate } from 'react-router-dom'

function Banners() {
  const nevigate = useNavigate()
  function view(){

    var element = document.getElementById("cityscroll")
    element.scrollIntoView()
  }
  return (
    <>
        <div className="container-fluid d-flex justify-content-center align-items-center bannger-background-img">  
                   <div className="row">
                   <div className="col-12 d-flex justify-content-center align-items-center">
                       <p className='text-center banner-text '>Engage With The Largest Packer Community and Network</p>
                    </div>
                    <div className="col-12 d-flex flex-row justify-content-center align-items-center ">
                        <button className="banner-btn me-2 " onClick={()=>nevigate("/getestimate1")}>Get Estimate</button>
                        <button className="banner-btn-outline ms-2" onClick={view}>Choose city</button>
                    </div>

                </div>
        
        </div>
    </>
  )
}

export default Banners