
import React,{useEffect,useState} from 'react'
import axios from '../../axios'
import Loader from '../../Loader'
import Toast from '../../Toast'
import './career.css'
import hiringBannerImg from '../assest/hiringbanner.png'
import { Link } from 'react-router-dom'
import hiringArr from './utils/hiringCarArr'


const Career = () => {
  const [content,setcontent] = useState({})
  const [isLoading,setIsLoading] = useState(true)


  const Careers =async () =>
  {
      try{
          setIsLoading(true)
          const response= await axios({
            method: "get",
           url:`/career`,
            
            } )
           
           if(response.status===200){
            const data = response?.data;
            setcontent(data?.career)
            // Toast(data.message,response.status)
            
            
           }
          }
         catch(err){
          const error = err.response.data
          Toast(error.message);
       
         }
         finally{
          setIsLoading(false)
         }
       
  
  }

  useEffect(() => {
    Careers()
    document.title="Career"

  },[])
  //console.log('career', content);
  return (
    <>
      {isLoading &&(<Loader/>)}
        <top>
        <div className="container-fluid mb-4 pb-3">
      {/* <div className="about-us-top" style={{background:`url(${content?.banner}) no-repeat center `}}> */}
      <div className="about-us-top-banner" >
      <img src={hiringBannerImg} alt="hiring" className='img-fluid' />
      </div>

      <div className="about-us-bottom mt-lg-4">
        <div className="aboutus-bottom-container mt-lg-4">
         
          <p id="description-text"   dangerouslySetInnerHTML={{__html: `${content?.content}`}}/>

        </div>
      </div>

      <div className="container ">
        <div className="container mt-4 mt-lg-4 pt-2 pt-lg-4 pb-3 ">
          <div className="row">
            <div className="col-12">
              <h3 className='fw-bold text-center'>We are Hiring</h3>
              <p className='text-center'>We’re currently hiring for the following positions.</p>
            </div>
            <div className="col-12">
                
            {
              hiringArr.map((val)=>(
                <>
                <div key={val.id} className="row hiring-card-border p-3">
                <div className="col-12 p-2 col-lg-8 d-flex flex-column justify-content-center align-items-start">
                 <span className='mb-3'>Posted {val.month}.</span>
                 <h3 className='mb-3 fw-bold'>{val.position}</h3>

                 <span className='mb-3'>{val.location} . <b>{val.role}</b></span>
            </div>
            <div className="col-12 col-lg-4 d-flex justify-content-center align-items-center">
                  <Link>support@budgetlogisticspackers.com</Link>
            </div>
            </div>
            </>
              ))
            }
            
            
            
       

            </div>
          </div>
        </div>
      </div>
      </div>
    </top>

   

    </>
  )
}

export default Career