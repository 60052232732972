import CustoerCarousel from './CustoerCarousel';
import './ourcustomers.css';

function OurCustomers() {
  return (
    <>

       <div className="container-fluid carousel-main-container px-lg-3 pt-5 pb-4">
        <div className="container">
        <div className="row">
        <div className="col-12">
                <h3 className='text-center first-heading'>Our Happy Customers</h3>
            </div>
        </div>
        </div>
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                <CustoerCarousel/>
                {/* <div className="carousel-container">
                   <CustoerCarousel/>
                 </div> */}
                </div>
            </div>

        </div>
       </div>
 
      </>
  )
}

export default OurCustomers