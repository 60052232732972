const hiringArr = [
    {
        id:1,
        month:'3 month ago',
        position:'Field Officer',
        location:'Banglore, Gurgaon,',
        role:'Part time'
    },
    {
        id:2,
        month:'7 month ago',
        position:'Customer Care Representative',
        location:'Banglore, Noida India ',
        role:'Full time'
    },
    {
        id:3,
        month:'1 month ago',
        position:'Associate Customer Service Manager',
        location:'Banglore, Gurgaon, Noida India',
        role:'Part time'
    },
    {
        id:4,
        month:'8 month ago',
        position:'Desk Officer',
        location:' Gurgaon, Noida India',
        role:'Full time'
    },

];


export default hiringArr;