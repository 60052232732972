import React from "react";

function ShippingPolicy() {
  return (
    <div className="container-fluid">
      <div className="container">
        <div className="row">
          <div className="col-12 my-4 py-4">
            <h3 className="text-center fw-bold py-2 mt-lg-4">
              Shipping Policy
            </h3>
            <h5 className="text-center text-lg-start">Introduction</h5>
            <p className="text-center text-lg-start">
              At Budget Logistics Packers, we are dedicated to providing
              reliable and efficient packing and moving services to ensure your
              belongings are transported safely and securely. This shipping
              policy outlines our procedures, timelines, and guidelines to help
              you understand our services better.
            </p>

            <h5 className="text-center text-lg-start">Shipping Services</h5>
            <h5 className="text-center text-lg-start">Local Moving</h5>
            <p className="text-center text-lg-start">
              Service Area: We offer local moving services within a 50-mile
              radius of our main office.
              <br />
              Timeline: Local moves are typically completed within 1-2 days,
              depending on the size of the move and specific customer
              requirements.
              <br />
              Timeline: Local moves are typically completed within 1-2 days,
              depending on the size of the move and specific customer
              requirements.
              <br />
              Packing: Our team provides professional packing services to ensure
              your items are securely packed and transported.
            </p>
            <h5 className="text-center text-lg-start">Long-Distance Moving</h5>
            <p className="text-center text-lg-start">
              Service Area: We provide long-distance moving services across the
              country.
              <br />
              Timeline: Long-distance moves may take 3-14 days, depending on the
              distance and any special requirements.
              <br />
              Tracking: Customers will receive regular updates on the status of
              their shipment. Commercial Moving
              <br />
              Service Area: We offer commercial moving services for businesses
              of all sizes.
              <br />
              Timeline: Commercial moves are planned and executed to minimize
              downtime, typically within a specified timeframe agreed upon with
              the client.
              <br />
              Special Handling: We handle office equipment, furniture, and
              sensitive documents with care and confidentiality.
            </p>
            <h5 className="text-center text-lg-start">Packing and Handling</h5>
            <h5 className="text-center text-lg-start">Packing Services</h5>
            <p className="text-center text-lg-start">
              Our team is trained to pack items securely to prevent damage
              during transportation. We provide all necessary packing materials,
              including boxes, bubble wrap, and packing tape. Special items,
              such as fragile and valuable items, are packed with extra care.
            </p>

            <h5 className="text-center text-lg-start">Loading and Unloading</h5>
            <p className="text-center text-lg-start">
              Our professional movers are skilled in loading and unloading items
              to ensure they are not damaged during the process.
              <br />
              We use appropriate equipment and techniques to handle heavy and
              bulky items.
            </p>

            <h5 className="text-center text-lg-start">Inventory Management</h5>
            <p className="text-center text-lg-start">
              We maintain a detailed inventory of all items being moved to
              ensure nothing is lost or misplaced. Customers receive a copy of
              the inventory list for their records.
            </p>

            <h5 className="text-center text-lg-start">Delivery Scheduling</h5>
            <p className="text-center text-lg-start">
              Delivery dates and times are scheduled in advance and confirmed
              with the customer.
              <br />
              We strive to meet delivery timelines and keep customers informed
              of any changes.
            </p>

            <h5 className="text-center text-lg-start">Unpacking Services</h5>
            <p className="text-center text-lg-start">
              Upon arrival, our team can assist with unpacking and setting up
              your items as per your instructions.
            </p>
            <h5 className="text-center text-lg-start">Inventory Management</h5>
            <p className="text-center text-lg-start">
              We maintain a detailed inventory of all items being moved to
              ensure nothing is lost or misplaced. Customers receive a copy of
              the inventory list for their records.
              <br />
              We remove all packing materials and debris, leaving your new space
              clean and organized. Damage and Claims
            </p>
            <h5 className="text-center text-lg-start">Damage and Claims

</h5>
            <h5 className="text-center text-lg-start">Damage Prevention</h5>
            <p className="text-center text-lg-start">
              
We take every precaution to prevent damage during the packing, transportation, and unpacking process.

              <br />
              In the rare event that an item is damaged, we have procedures in place to address and resolve the issue promptly.

            </p>
            <h5 className="text-center text-lg-start">Insurance</h5>
            <p className="text-center text-lg-start">
            Customers are encouraged to purchase insurance for their belongings to provide additional protection.

              <br />
              In the event of damage, claims can be made according to our refund policy.

            </p>
          

            {/* <h5 className="text-center text-lg-start">How to Cancel</h5> */}
            <p className="text-center text-lg-start">
              Contact Us: To cancel your order, please contact our customer
              service team as soon as possible.
            </p>

            <address className="text-center text-lg-start">
              B-44,1st floor, Dev Raj Urs Truck Terminal, opp. Kanteerva Studio,
              Yeshwantpur, Bangalore - 560022,
              <br />
              Contact Number: 9916693666
              Email : support@budgetlogisticspackers.com
            </address>

          
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShippingPolicy;
