import React from 'react'
import './growing.css'
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation,Autoplay } from "swiper";
//import { image5,image6,image7,image8,image9,image10,image11,image12 } from '../assest';
import image13 from '../assest/slider/image13.png';
import image14 from '../assest/slider/image14.png';
import image15 from '../assest/slider/image15.png';
import image16 from '../assest/slider/image16.png';
import image19 from '../assest/slider/image19.jpg'

import image18 from '../assest/slider/image18.png';

const Slider = () => {
  return (
    <>  
        <div className="cotnainer-fluid py-2 py-lg-4 ">
          <h3 className='text-center first-heading'>Brand We Have Served For</h3>
        <div className="slider  padding4rem">
    <Swiper 

        slidesPerView={5}
        spaceBetween={30}
        slidesPerGroup={5}
        loop={true}
        loopFillGroupWithBlank={true}
        // pagination={{
        //   clickable: true,
        // }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        // navigation={true}
        modules={[Autoplay]}
 
        className="mySwiper"
      >
        {/* <SwiperSlide> <img src={image5 } alt='' className='swiperImg'/></SwiperSlide>
        <SwiperSlide><img src={image6} alt='' className='swiperImg'/></SwiperSlide>
        <SwiperSlide><img src={image7} alt='' className='swiperImg'/></SwiperSlide>
        <SwiperSlide><img src={image8} alt='' className='swiperImg'/></SwiperSlide>
        <SwiperSlide><img src={image9} alt='' className='swiperImg'/></SwiperSlide>
        <SwiperSlide><img src={image10} alt='' className='swiperImg'/></SwiperSlide>
        <SwiperSlide><img src={image11} alt='' className='swiperImg'/></SwiperSlide>
        <SwiperSlide><img src={image12} alt='' className='swiperImg'/></SwiperSlide> */}
        <SwiperSlide><img src={image13} alt='' className='swiperImg'/></SwiperSlide>
        <SwiperSlide><img src={image14} alt='' className='swiperImg'/></SwiperSlide>
        <SwiperSlide><img src={image15} alt='' className='swiperImg'/></SwiperSlide>
        <SwiperSlide><img src={image19} alt='' className='swiperImg'/></SwiperSlide>
        <SwiperSlide><img src={image16} alt='' className='swiperImg'/></SwiperSlide>
        <SwiperSlide><img src={image18} alt='' className='swiperImg'/></SwiperSlide>  
      </Swiper>
      </div>
      </div>
    </>
  )
}

export default Slider