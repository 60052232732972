import React from 'react'
import './service.css'
import { warehouse,train,exchange,canvas,delivery,briefcase } from '../assest/index'



const Services = () => {
  return (
    <>
        
        <div className="container-fluid main-service-container">
            <div className="container">
                <div className="row">
                    <div className="col-12 d-flex flex-column justify-content-center align-items-center">
                    <p className="main-heading text-center dark-orange">OUR SERVICES</p>
                     <h2 className="submain-heading  pb-3 text-center">Get quality service from high rated staff with peace of mind from Budget Logistics</h2>
                    </div>
                </div>
                <div className="row g-3 g-lg-4">
                        {/* left card container */}
                    <div className="col-12 col-lg-6">
                         <div className="row g-2 gx-lg-3 gy-lg-4">
                            {/* card -1 */}
                            <div className="col-12 col-lg-6">
                            <div className=" card-content border pt-3 pb-1 px-2 rounded">
                            <div className="row">
                          <div className="col-12  text-center">
                          <img src={train} alt="swws" className="service-card-img text-primary img-fluid"/>
                          </div>
                          <div className="col-12 py-1">
                          <p className="text-service text-center">International Relocation</p>
                   
                          </div>
                          <div className="col-12 d-flex justify-content-center align-items-center">
                          <p className="text-service-small text-center"> 32+ Branches Pan India We move source to destination very effcient time period with very without any damage </p>
                          </div>
                        </div>
                            </div>
                            </div>
                         {/* card2 */}
                            <div className="col-12 col-lg-6">
                            <div className="card-content border pt-3 pb-1 px-2 rounded">
                             <div className="row">
                          <div className="col-12 text-center">
                          <img src={exchange} alt="" className="service-card-img img-fluid"/>
                          </div>
                          <div className="col-12 py-1">
                          <p className="text-service text-center">Vehicle Relocation</p>
                   
                          </div>
                          <div className="col-12">
                          <p className="text-service-small text-center">Our Professional and highly trained team members will makes your shifting very smooth and without any damage. </p>
                          </div>
                     </div>
                     </div>
                            </div>

                         {/* card-3 */}
                            <div className="col-12 col-lg-12">
                            <div className="card-content border pt-3 pb-1 px-2 rounded">
                          <div className="row">
                          <div className="col-12 text-center">
                          <img src={canvas} alt="swws" className="service-card-img img-fluid"/>
                          </div>
                          <div className="col-12 py-1">
                          <p className="text-service text-center">Corporate Relocation</p>
                   
                          </div>
                          <div className="col-12">
                          <p className="text-service-small text-center"> We are well equipped with our team of professional staff that use best equipments for packing, loading, unloading materials. </p>
                          </div>
                     </div>
                     </div>
                            </div>

                         </div>
                    </div>
                     {/* Right Card Container */}
                    <div className="col-lg-6">
                         <div className="row g-2 gx-lg-3 gy-lg-4">
                         {/* card - 1 */}
                            <div className="col-12 col-lg-6">
                            <div className="card-content border pt-3 pb-1 px-2 rounded">
                         <div className="row">
                          <div className="col-12 text-center">
                         
                          <img src={ delivery} alt="swws" className="service-card-img img-fluid"/>
                          </div>
                          <div className="col-12 py-1">
                          <p className="text-service text-center">Domestic Relocation</p>
                   
                          </div>
                          <div className="col-12">
                          <p className="text-service-small text-center"> We are well equipped with our team of professional staff that use best equipments for packing, loading, unloading materials. </p>
                          </div>
                     </div>
                     </div>
                            </div>
                            {/* card-2 */}
                            <div className="col-12 col-lg-6">
                            <div className="card-content border pt-3 pb-1 px-2 rounded">
                         <div className="row">
                          <div className="col-12 text-center">
                          <img src={briefcase} alt="swws" className="service-card-img img-fluid"/>
                          </div>
                          <div className="col-12 py-1">
                          <p className="text-service text-center">Office Relocation</p>
                   
                          </div>
                          <div className="col-12">
                          <p className="text-service-small text-center">We move source to destination very effcient time period with very without any damage.enjoy our service </p>
                          </div>
                     </div>
                     </div>
                            </div>
                            {/* card-3 */}
                            <div className="col-12 col-lg-12">
                            <div className="card-content border pt-3 pb-1 px-2 rounded">
                          <div className="row">
                          <div className="col-12 text-center">
                          <img src={warehouse} alt="swws" className="service-card-img img-fluid"/>
                          </div>
                          <div className="col-12 py-1">
                          <p className="text-service text-center">Warehousing and Storage</p>
                   
                          </div>
                          <div className="col-12">
                          <p className=" text-service-small text-center"> Flexible Pricing. Competitive Quote. We provide Best and reliable Packing and moving service in Gurgaon at lowest cost. </p>
                          </div>
                     </div>
                     </div>
                            </div>
                         </div>
                    </div>

                </div> 
            </div>
        </div>



{/* <div className="about-section py-5 section-margin " style={{padding:'1rem'}}>
    <div className="container service ">
        <div className="row">
            <div className="col-sm-12 col-lg-12">
                <p className="section-subheading text-center">OUR SERVICES</p>
                <h2 className="section-heading text-center">Get quality service from high rated staff with peace of mind from Budget Logistics</h2>
            </div>
        </div>
        <div className="parent-of-card" >
            <div className="" >
                <div className="text-center service-card">
                    <div className="service-tile">
                       <img src={train} alt="swws" className="img-fluid"/>
                       
                    </div>
                   <p className="service-tile-heading">International Relocation</p>
                   <p className="service-tile-text"> 32+ Branches Pan India We move source to destination very effcient time period with very without any damage </p>
                </div>
            </div>
            <div className="" >
                <div className="text-center service-card">
                    <div className="service-tile">
                       <img src={delivery} alt="" className="img-fluid"/>
                       
                    </div>
                   <p className="service-tile-heading">Domestic Relocation</p>
                   <p className="service-tile-text">We are well equipped with our team of professional staff that use best equipments for packing, loading, unloading materials.</p>
                </div>
            </div>
            <div className="" >
                <div className="text-center service-card">
                    <div className="service-tile">
                       <img src={exchange} alt="" className="img-fluid"/>
                       
                    </div>
                   <p className="service-tile-heading">Vehicle Relocation</p>
                   <p className="service-tile-text">Our Professional and highly trained team members will makes your shifting very smooth and without any damage. </p>
                </div>
            </div>

            <div className="" >
                <div className="text-center service-card">
                    <div className="service-tile">
                       <img src={briefcase} alt="" className="img-fluid"/>
                       
                    </div>
                   <p className="service-tile-heading">Office Relocation</p>
                   <p className="service-tile-text">We move source to destination very effcient time period with very without any damage.enjoy our service</p>
                </div>
            </div>
            <div className="" >
                <div className="text-center service-card">
                    <div className="service-tile">
                       <img src={warehouse} alt="" className="img-fluid"/>
                       
                    </div>
                   <p className="service-tile-heading">Corporate Relocation</p>
                   <p className="service-tile-text">We are well equipped with our team of professional staff that use best equipments for packing, loading, unloading materials.</p>
                </div>
            </div>
            <div className="" >
                <div className="text-center service-card">
                    <div className="service-tile">
                       <img src={canvas} alt="" className="img-fluid"/>
                       
                    </div>
                   <p className="service-tile-heading">Warehousing and Storage</p>
                   <p className="service-tile-text">  Flexible Pricing.  Competitive Quote. We provide Best and reliable Packing and moving service in Gurgaon at lowest cost.</p>
                </div>
            </div>
        </div>
    </div>
</div> */}

    </>
  )
}

export default Services