import React, { useEffect } from 'react'
import { useState,useCallback ,useContext} from 'react';
import './uniqueselling.css'
import { ImgArr } from './utils/SellingImg';
import Van from '../assest/van2.png'
//import { customer,quality,premium,rating,team,rectangle,group } from '../assest'
//import Gallery from "react-photo-gallery";
//import Carousel, { Modal, ModalGateway } from "react-images";
//import {SlideshowLightbox, initLightboxJS} from 'lightbox.js-react'
// import Toast from '../../Toast';
// import { AuthContext } from '../../AuthProvider';
// import axios from '../../axios';
// import Loader from '../../Loader';
import { Link } from 'react-router-dom';



const Uniqueselling = () => {
  // const {HomePage,setHomePage} = useContext(AuthContext)
  // const {gallery} = HomePage
  // const [isLoading,setIsLoading]=useState(true)
 //   const [currentImage, setCurrentImage] = useState(0);
  //  const [viewerIsOpen, setViewerIsOpen] = useState(false);

    // const galleryfun =async () =>
    // {
    //     try{
    //         setIsLoading(true)
    //         const response= await axios({
    //           method: "get",
    //          url:'/get_gallery',
              
    //           } )
             
    //          if(response.status===200){
    //           const data = response.data;
    //             const photo=[]
    //           data?.gallery?.forEach((element, index) =>{
    //               var obj = {
    //                 src: element?.image,
    //                    width: 3,
    //                   height:  3
    //               }
    //               photo.push(obj);

    //           })
    //           setHomePage((p)=>({...p,["gallery"]:photo}))
    //           // Toast(data.message,response.status)
              
              
    //          }
    //         }
    //        catch(err){
    //         const error = err.response.data
    //         Toast(error.message);
            
         
         
    //        }
    //        finally{
    //         setIsLoading(false)
    //        }
         
    
    // }

    // useEffect(()=>{
    //   !gallery?.length ? galleryfun():setIsLoading(false)

    // },[])
  
    // const openLightbox = useCallback((event, { gallery, index }) => {
    //   setCurrentImage(index);
    //   setViewerIsOpen(true);
    // }, []);
  
    // const closeLightbox = () => {
    //   setCurrentImage(0);
    //   setViewerIsOpen(false);
    // };

    // const photos = [
    //     {
    //       src: "https://source.unsplash.com/2ShvY8Lf6l0/800x599",
    //       width: 3,
    //       height: 2
    //     },
    //     {
    //       src: "https://source.unsplash.com/Dm-qxdynoEc/800x799",
    //       width: 1,
    //       height: 1
    //     },
    //     {
    //       src: "https://source.unsplash.com/qDkso9nvCg0/600x799",
    //       width: 3,
    //       height: 4
    //     },
    //     {
    //       src: "https://source.unsplash.com/iecJiKe_RNg/600x799",
    //       width: 3,
    //       height: 4
    //     },
    //     {
    //       src: "https://source.unsplash.com/epcsn8Ed8kY/600x799",
    //       width: 3,
    //       height: 4
    //     },
    //     {
    //       src: "https://source.unsplash.com/NQSWvyVRIJk/800x599",
    //       width: 4,
    //       height: 3
    //     },
    //     {
    //       src: "https://source.unsplash.com/zh7GEuORbUw/600x799",
    //       width: 3,
    //       height: 4
    //     },
    //     {
    //       src: "https://source.unsplash.com/PpOHJezOalU/800x599",
    //       width: 4,
    //       height: 3
    //     },
    //     {
    //       src: "https://source.unsplash.com/I1ASdgphUH4/800x599",
    //       width: 4,
    //       height: 3
    //     }
    //   ];
      
  return (
    <>
    {/* {isLoading &&(<Loader/>)} */}

     <div className="container-fluid py-5">
      <div className="container">
      <div className="row">

       {/* unique selling left container */}
      <div className="col-12 col-lg-6">
        <div className="row">
          <div className="col-12 col-lg-12">
          <h2 class="section-heading text-center text-lg-start">OUR UNIQUE SELLING PROPOSITION</h2>
            
          <div className="d-lg-none py-2 col-12 col-lg-6">
        <div className="img-container">
          <img src={Van} className='img-fluid ms-2' alt="loader van" />
        </div>
      </div>

          <p class="business-text pb-lg-3 py-2 py-lg-0 text-center text-lg-start">Your Business Success Built On A Powerful Customer Experience Platform</p>
          </div>
        </div>
        <div className="row g-5">
        {ImgArr.map((val)=>(
          <div key={val.id} className="col-6 col-lg-6 ">
              <div className="row pointer  ">
              <div className="col-12 col-lg-4 d-flex justify-content-center align-items-center">
                <img src={val.Img} alt={val.Img} className='img-fluid' />
              </div>
              <div className="col-12 col-lg-8  pt-lg-3 d-flex justify-content-center align-items-center">
                  <Link to="/"><p className='py-2 py-lg-0 smaller-heading text-center text-lg-start'>{val.Text}</p></Link> 
              </div>
          </div>
          </div>
        ))}
        </div>
      </div>

      {/* unique selling left container */}
      <div className="col-12 d-none d-lg-block col-lg-6">
        <div className="img-container">
          <img src={Van} className='img-fluid ms-2' alt="loader van" />
        </div>
      </div>
      </div>
      </div>
     </div>




{/* {happy face } */}
{/* <div className="happy-face section-margin section-padding">
<div className="container" >
<div >

<p class="section-subheading text-center">HAPPY FACES </p>
<h2 class="section-heading text-center">We Have Spread Some Happiness</h2>
</div>
</div>
</div> */}
{/* <Gallery photos={gallery} onClick={openLightbox} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={gallery.map(x => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway> */}
    </>
  )
}

export default Uniqueselling