import React from "react";

function RefundPolicy() {
  return (
    <div className="container-fluid">
      <div className="container">
        <div className="row">
          <div className="col-12 my-4 py-4">
            <h3 className="text-center fw-bold py-2 mt-lg-4">Refund Policy</h3>
            <h5 className="text-center text-lg-start">Introduction</h5>
            <p className="text-center text-lg-start">
            At Budget Logistics Packers, we strive to provide exceptional moving and packing services to ensure a seamless and stress-free relocation experience for our valued customers. We understand that moving can be a challenging process, and we are committed to handling your belongings with the utmost care. However, we acknowledge that unforeseen circumstances may arise during transportation or shifting. To address such situations, we have outlined our refund policy below.

            </p>
            
            <h5 className="text-center text-lg-start">Coverage and Compensation</h5>
            {/* <h5 className="text-center text-lg-start">Item Damage During Transportation or Shifting</h5> */}
            <p className="text-center text-lg-start">
            In the event that any item is damaged during the transportation or shifting process, Budget Logistics Packers will take full responsibility, provided that the damage occurred while the item was in our care.<br/>
            To qualify for compensation, the damaged item must have been packed and moved by our team.


            </p>
            <h5 className="text-center text-lg-start">Insurance Coverage</h5>
            <p className="text-center text-lg-start">
            Customers who have purchased insurance from Budget Logistics Packers for their belongings are eligible for compensation for any item damaged during the move.
The insurance must be purchased prior to the move and must cover the specific item in question.


            </p>
            <h5 className="text-center text-lg-start">Compensation Terms</h5>
            <p className="text-center text-lg-start">
            For insured items, Budget Logistics Packers will compensate up to 80% of the declared value of the damaged item as the repairable cost.<br/>
            The declared value is the amount specified by the customer at the time of purchasing insurance.
Compensation is subject to verification and assessment by our claims team to determine the extent of the damage and the repairable cost.


            </p>
            <h5 className="text-center text-lg-start">Claim Process</h5>
            <p className="text-center text-lg-start">
            Any damage must be reported to Budget Logistics Packers within 48 hours of delivery.
Reports should include a detailed description of the damage, photographs, and any supporting documentation
            </p>
            <h5 className="text-center text-lg-start">Assessment and Verification</h5>
            <p className="text-center text-lg-start">
            Our claims team will review the reported damage and may request additional information or an inspection of the damaged item.
            <br/>
            The assessment process typically takes 5-7 business days from the receipt of all necessary information.


            </p>
            <h5 className="text-center text-lg-start">Compensation Payment</h5>
            <p className="text-center text-lg-start">
            Once the claim is approved, Budget Logistics Packers will process the compensation payment within 10 business days.<br/>
            Compensation will be paid directly to the customer, or in some cases, directly to the repair service provider.
            </p>

            <h5 className="text-center text-lg-start">Limitations and Exclusions</h5>
            <p className="text-center text-lg-start">
            Items not packed or moved by Budget Logistics Packers are not covered under this policy.
            Pre-existing damages, wear and tear, or damages due to natural disasters are excluded from compensation.<br/>
            The maximum liability is limited to the declared value of the item as per the insurance policy.


            </p>



            <address className="text-center text-lg-start">
            B-44,1st floor, Dev Raj Urs Truck Terminal, opp. Kanteerva Studio, Yeshwantpur, Bangalore - 560022,<br/>
            Contact Number: 9916693666
            Email : support@budgetlogisticspackers.com
            </address>


{/*             
            <h5 className="text-center text-lg-start">Contacting The Website Administrators</h5>
            <p className="text-center text-lg-start">
              If you have any questions about this privacy statement, the
              practices of this site, or your dealings with this web site,
              please contact: Environmental Policy
            </p>
            <p className="text-center text-lg-start">
              If you have any questions about this privacy statement, the
              practices of this site, or your dealings with this web site,
              please contact us.
            </p>
            <h5 className="text-center text-lg-start">Environmental Policy</h5>
            <p className="text-center text-lg-start">
              Environmental responsibilities are driven by our commitment to
              preserve the environment and are integral to the way we do
              business.
            </p>
            <ol className="text-center text-lg-start">
              <li className="text-start">
                We are committed to deal proactively with Climate Change issue
                by efficient use of natural resources & energy; reducing and
                preventing pollution; promoting waste avoidance and recycling
                measures; and product stewardship. We shall identify, assess and
                manage our environment impact. We shall regularly monitor,
                review and report publicly our environmental performance. We
                shall enhance awareness, skill and competence of our employees
                and contractors so as to enable them to demonstrate their
                involvement, responsibility and accountability for sound
                environmental performance.
              </li>
              <li className="text-start">
                We are committed to continual improvement in our environmental
                performance. We shall set objective-targets, develop, implement
                and maintain management standards and systems, and go beyond
                compliance of the relevant industry standards, legal and other
                requirements.
              </li>
              <li className="text-start">
                We will truly succeed when we sustain our environmental
                achievement and are valued by the communities in which we work.
              </li>
            </ol>
            <h5 className="text-center text-lg-start">Information Use:</h5>
            <p className="text-center text-lg-start">
              We may also collect and store personal information provided by you
              from time to time on the Website. We may use your personal
              information to provide you with the products and services you have
              requested. We may also use your personal information to
              communicate with you about our products and services, to conduct
              research and analysis, and to improve our services. We may collect
              and use such information from you that we consider necessary for
              achieving a seamless, efficient and safe experience, customized to
              your needs including:
            </p>
            <p className="fw-bold text-center text-lg-start">
              We may use your personal information for the following purposes:
            </p>
            <ul className="text-center text-lg-start">
              <li className="text-start">provide services</li>
              <li className="text-start">respond to an individual request</li>
              <li className="text-start">maintaining contact with clients</li>
              <li className="text-start">
                keeping clients and others informed of the services we offer and
                industry developments that may be of interest to them, and
                notify them of our service offerings
              </li>
              <li className="text-start">
                sending you marketing and promotional communications general
                management and accounting purposes such as invoicing and account
                management.
              </li>
              <li className="text-start">providing customer support and services you request.</li>
              <li className="text-start">resolve disputes if any and troubleshooting.</li>
            </ul>
            <p className="fw-bold text-center text-lg-start">
              all other lawful purposes related to our business and in
              furtherance to the fulfilment of a lawful contract
            </p>
            <h6 className="text-center text-lg-start">compliance with laws</h6>
            <p className="text-center text-lg-start">
              Personal information collected by us through the Appsis used for
              the intended purposes stated at the time the information is
              collected or for the purposes specified above.
            </p>
            <h5 className="text-center text-lg-start">Information Sharing:</h5>
            <p className="text-center text-lg-start">
              Personal Information may be shared with third-party service
              providers who perform services on our behalf, such as vendors who
              assist in managing our services, payment processors, or
              transactional message processors. Additionally, Personal
              Information may be shared with our group companies. These
              third-party service providers and partners will use the
              information we provide in accordance with their respective privacy
              policies, and we take reasonable measures to ensure that they are
              bound by confidentiality agreements to protect Personal
              Information on our behalf.
            </p>
            <p className="text-center text-lg-start">
              In certain circumstances, we may share Personal Information to
              comply with legal obligations or to safeguard our own interests.
              This may include sharing Personal Information in response to a
              court order or government request from an investigatory body.
              Personal Information may also be shared when investigating
              potentially fraudulent activity. Furthermore, Personal Information
              may be shared with any successor to all or part of our business.
            </p>
            <h5 className="text-center text-lg-start">Data Security:</h5>
            <p className="text-center text-lg-start">
              We use industry-standard security measures to protect your
              personal information from unauthorized access and use. However, no
              method of transmission over the internet or method of electronic
              storage is 100% secure. Therefore, while we strive to use
              commercially acceptable means to protect your personal
              information, we cannot guarantee its absolute security.
            </p>
            <h5 className="text-center text-lg-start">Cookies:</h5>
            <p className="text-center text-lg-start">
              Cookies are small data files that are placed on your computer or
              mobile device when you visit website.{" "}
            </p>
            <p className="text-center text-lg-start">
              We use cookies to gather information and customize your experience
              with our Services. These technologies enable us to gain an
              understanding of your particular App preferences and to offer you
              a more personalized experience in the future. They help us
              recognize your device, store your settings and preferences, and
              provide you with content and advertising that align with your
              interests.
            </p>
            <p className="text-center text-lg-start">
              Cookies also allow us to perform searches and analytics, as well
              as provide security and administrative functions. As we adopt
              additional technologies, we may also collect information through
              other means.
            </p>
            <h5 className="text-center text-lg-start">Links to Other Websites:</h5>
            <p className="text-center text-lg-start">
              Our website may contain links to other websites . We are not
              responsible for the privacy practices or content of these other
              websites.
            </p>
            <h5 className="text-center text-lg-start">Contact Us:</h5>
            <p className="text-center text-lg-start">
              If you have any questions or concerns about this privacy policy,
              or for requesting the deletion of your personal information,
              please contact us at http://budgetlogisticspackers.com
            </p>
            <address className="text-center text-lg-start">
              office : Budget logistic and packers Address : No. 272, Bharat
              Tower, Ashok Vihar Phase - 3, Futa Road, Near Krishna Chock,
              Gurugram - 122001 +91 93-116-93-666 +91 99-166-93-666
              Email: info@budgetlogistic.com.
            </address> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RefundPolicy;
