import React from "react";

function CancellationPolicy() {
  return (
    <div className="container-fluid">
      <div className="container">
        <div className="row">
          <div className="col-12 my-4 py-4">
            <h3 className="text-center fw-bold py-2 mt-lg-4">Cancellation Policy</h3>
            <h5 className="text-center text-lg-start">Introduction</h5>
            <p className="text-center text-lg-start">
              At Budget Logistics Packers, we understand that plans can change
              unexpectedly, and we aim to provide flexibility to our customers
              when it comes to their moving needs. To ensure transparency and
              clarity, we have outlined our cancellation policy below.
            </p>

            <h5 className="text-center text-lg-start">Cancellation Terms</h5>
            <h5 className="text-center text-lg-start">
              Cancellation Prior to 7 Days
            </h5>
            <p className="text-center text-lg-start">
              If a client cancels their order 7 days or more before the
              scheduled moving date, Budget Logistics Packers will refund the
              complete amount paid for the services.
              <br />
              The refund process will be initiated immediately upon receiving
              the cancellation request, and the amount will be credited to the
              original payment method within 10 business days.
            </p>
            <h5 className="text-center text-lg-start">
              Cancellation Within 7 Days
            </h5>
            <p className="text-center text-lg-start">
              If a client cancels their order less than 7 days before the
              scheduled moving date, no refund will be issued.
              <br />
              This policy is in place due to the allocation of resources and
              scheduling of our team, which incurs costs that cannot be
              recovered on short notice.
            </p>
            <h5 className="text-center text-lg-start">How to Cancel</h5>
            <p className="text-center text-lg-start">
              Contact Us: To cancel your order, please contact our customer
              service team as soon as possible.
            </p>

            <address className="text-center text-lg-start">
              B-44,1st floor, Dev Raj Urs Truck Terminal, opp. Kanteerva Studio,
              Yeshwantpur, Bangalore - 560022,
              <br />
              Contact Number: 
              Email : support@budgetlogisticspackers.com
            </address>

            {/*             
            <h5 className="text-center text-lg-start">Contacting The Website Administrators</h5>
            <p className="text-center text-lg-start">
              If you have any questions about this privacy statement, the
              practices of this site, or your dealings with this web site,
              please contact: Environmental Policy
            </p>
            <p className="text-center text-lg-start">
              If you have any questions about this privacy statement, the
              practices of this site, or your dealings with this web site,
              please contact us.
            </p>
            <h5 className="text-center text-lg-start">Environmental Policy</h5>
            <p className="text-center text-lg-start">
              Environmental responsibilities are driven by our commitment to
              preserve the environment and are integral to the way we do
              business.
            </p>
            <ol className="text-center text-lg-start">
              <li className="text-start">
                We are committed to deal proactively with Climate Change issue
                by efficient use of natural resources & energy; reducing and
                preventing pollution; promoting waste avoidance and recycling
                measures; and product stewardship. We shall identify, assess and
                manage our environment impact. We shall regularly monitor,
                review and report publicly our environmental performance. We
                shall enhance awareness, skill and competence of our employees
                and contractors so as to enable them to demonstrate their
                involvement, responsibility and accountability for sound
                environmental performance.
              </li>
              <li className="text-start">
                We are committed to continual improvement in our environmental
                performance. We shall set objective-targets, develop, implement
                and maintain management standards and systems, and go beyond
                compliance of the relevant industry standards, legal and other
                requirements.
              </li>
              <li className="text-start">
                We will truly succeed when we sustain our environmental
                achievement and are valued by the communities in which we work.
              </li>
            </ol>
            <h5 className="text-center text-lg-start">Information Use:</h5>
            <p className="text-center text-lg-start">
              We may also collect and store personal information provided by you
              from time to time on the Website. We may use your personal
              information to provide you with the products and services you have
              requested. We may also use your personal information to
              communicate with you about our products and services, to conduct
              research and analysis, and to improve our services. We may collect
              and use such information from you that we consider necessary for
              achieving a seamless, efficient and safe experience, customized to
              your needs including:
            </p>
            <p className="fw-bold text-center text-lg-start">
              We may use your personal information for the following purposes:
            </p>
            <ul className="text-center text-lg-start">
              <li className="text-start">provide services</li>
              <li className="text-start">respond to an individual request</li>
              <li className="text-start">maintaining contact with clients</li>
              <li className="text-start">
                keeping clients and others informed of the services we offer and
                industry developments that may be of interest to them, and
                notify them of our service offerings
              </li>
              <li className="text-start">
                sending you marketing and promotional communications general
                management and accounting purposes such as invoicing and account
                management.
              </li>
              <li className="text-start">providing customer support and services you request.</li>
              <li className="text-start">resolve disputes if any and troubleshooting.</li>
            </ul>
            <p className="fw-bold text-center text-lg-start">
              all other lawful purposes related to our business and in
              furtherance to the fulfilment of a lawful contract
            </p>
            <h6 className="text-center text-lg-start">compliance with laws</h6>
            <p className="text-center text-lg-start">
              Personal information collected by us through the Appsis used for
              the intended purposes stated at the time the information is
              collected or for the purposes specified above.
            </p>
            <h5 className="text-center text-lg-start">Information Sharing:</h5>
            <p className="text-center text-lg-start">
              Personal Information may be shared with third-party service
              providers who perform services on our behalf, such as vendors who
              assist in managing our services, payment processors, or
              transactional message processors. Additionally, Personal
              Information may be shared with our group companies. These
              third-party service providers and partners will use the
              information we provide in accordance with their respective privacy
              policies, and we take reasonable measures to ensure that they are
              bound by confidentiality agreements to protect Personal
              Information on our behalf.
            </p>
            <p className="text-center text-lg-start">
              In certain circumstances, we may share Personal Information to
              comply with legal obligations or to safeguard our own interests.
              This may include sharing Personal Information in response to a
              court order or government request from an investigatory body.
              Personal Information may also be shared when investigating
              potentially fraudulent activity. Furthermore, Personal Information
              may be shared with any successor to all or part of our business.
            </p>
            <h5 className="text-center text-lg-start">Data Security:</h5>
            <p className="text-center text-lg-start">
              We use industry-standard security measures to protect your
              personal information from unauthorized access and use. However, no
              method of transmission over the internet or method of electronic
              storage is 100% secure. Therefore, while we strive to use
              commercially acceptable means to protect your personal
              information, we cannot guarantee its absolute security.
            </p>
            <h5 className="text-center text-lg-start">Cookies:</h5>
            <p className="text-center text-lg-start">
              Cookies are small data files that are placed on your computer or
              mobile device when you visit website.{" "}
            </p>
            <p className="text-center text-lg-start">
              We use cookies to gather information and customize your experience
              with our Services. These technologies enable us to gain an
              understanding of your particular App preferences and to offer you
              a more personalized experience in the future. They help us
              recognize your device, store your settings and preferences, and
              provide you with content and advertising that align with your
              interests.
            </p>
            <p className="text-center text-lg-start">
              Cookies also allow us to perform searches and analytics, as well
              as provide security and administrative functions. As we adopt
              additional technologies, we may also collect information through
              other means.
            </p>
            <h5 className="text-center text-lg-start">Links to Other Websites:</h5>
            <p className="text-center text-lg-start">
              Our website may contain links to other websites . We are not
              responsible for the privacy practices or content of these other
              websites.
            </p>
            <h5 className="text-center text-lg-start">Contact Us:</h5>
            <p className="text-center text-lg-start">
              If you have any questions or concerns about this privacy policy,
              or for requesting the deletion of your personal information,
              please contact us at http://budgetlogisticspackers.com
            </p>
            <address className="text-center text-lg-start">
              office : Budget logistic and packers Address : No. 272, Bharat
              Tower, Ashok Vihar Phase - 3, Futa Road, Near Krishna Chock,
              Gurugram - 122001 +91 93-116-93-666 +91 99-166-93-666
              Email: info@budgetlogistic.com.
            </address> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CancellationPolicy;
