import React from 'react'
import './termcondition.css'
function TermAndCondition() {
  return (
     <div className="container-fluid py-2">
        <div className="container mt-3">
        <br />
        <br />
        
            <div className="col-12">
                <h3 className='terms-heading pt-2'>Terms and Conditions</h3>
            </div>
            <div className="col-12">
                <p className='terms-content'>
                Welcome to budgetlogisticspackers, a leading provider of packers and movers services. These terms and conditions govern your use of our services. By booking our services, you agree to comply with these terms and conditions. Please read them carefully before proceeding.
                </p>
            </div>
            <div className="col-12">
                <h3 className='terms-heading pt-2'>Service Agreement</h3>
                <p className='terms-content'>
                By booking our packers and movers services, you enter into a binding agreement with budgetlogisticspackers. This agreement encompasses the terms and conditions outlined herein, along with any other specific agreements made in writing between you and our company.
                </p>
                <h3 className='terms-heading pt-2'> Service Booking </h3>
                <p className='terms-content'>To avail of our services, you must provide accurate and complete information during the booking process. This includes details such as the pickup and delivery locations, the type and quantity of items to be moved, and any specific requirements or instructions.</p>
                
                <h3 className='terms-heading pt-2'> Service Charges and Payment</h3>
                <p className='terms-content'>
                The service charges will be communicated to you during the booking process or through a written quotation. You agree to pay the specified amount in full before the scheduled moving date. Payment can be made through our accepted payment methods, and no service shall be rendered until payment is received.
                </p>
                
                <h3 className='terms-heading'> Cancellation and Rescheduling</h3>
                <p className='terms-content'>
                If you need to cancel or reschedule your booking, you must notify us in writing or through our customer support helpline at least 7 days before the scheduled moving date. Cancellations made within 4 days of the moving date may be subject to cancellation fees. Rescheduling is subject to the availability of our services.
                </p>

                <h3 className='terms-heading pt-2'> Responsibilities of Customers</h3>
                <ul>
                    <li>
                        <p className='terms-content'> Provide Accurate Information: You are responsible for providing accurate and complete information during the booking process. Any discrepancies may result in service delays or additional charges.</p>
                    </li>
                    <li>
                        <p className='terms-content'> Packing and Preparation: It is your responsibility to ensure that all items are properly packed and ready for transportation. Fragile items must be appropriately labeled, and you must inform our team of any delicate or valuable items.</p>
                    </li>
                    <li>
                        <p className='terms-content'>Accessibility: You must ensure that our team has clear and safe access to the pickup and delivery locations. Any obstacles or impediments that may hinder the moving process must be communicated in advance.</p>
                    </li>
                    <li>
                        <p className='terms-content'>Prohibited Items: You must not include hazardous, illegal, or perishable items in the shipment. It is your responsibility to adhere to all relevant laws and regulations.</p>
                    </li>
                </ul>

                <h3 className='terms-heading pt-2'>Our Responsibilities</h3>
                <ol>
                    <li>
                        <p className='terms-content'> Safe Handling: Our team will handle your belongings with utmost care during the packing, loading, transportation, unloading, and unpacking processes.</p>
                    </li>
                    <li>
                        <p className='terms-content'>
                        Transportation: We will endeavor to deliver your items within the agreed timeframe. However, unforeseen circumstances such as weather conditions or traffic may cause delays.
                        </p>
                    </li>
                    <li>
                        <p className='terms-content'>
                        Insurance: Our company may provide insurance coverage for your items during transportation, subject to the terms and conditions of the insurance policy.
                        </p>
                    </li>
                </ol>

                <h3 className='terms-heading pt-2'>Limitation of Liability</h3>
                <p className='terms-content'>While we take every precaution to ensure the safe and timely transportation of your belongings, we shall not be liable for any damages or losses arising from unforeseen events, accidents, or circumstances beyond our control.</p>
               
               <h3>Complaints and Disputes</h3>
               <p className='terms-content'>If you have any complaints or disputes regarding our services, please contact our customer support helpline. We will make every effort to resolve the issue promptly and amicably.</p>
            
            <h3 className='terms-heading pt-2'>Amendments to Terms and Conditions</h3>
            <p className='terms-content'>We reserve the right to update or modify these terms and conditions at any time. Any changes will be effective immediately upon posting on our website. It is your responsibility to review these terms periodically.</p>
            
            <h3 className='terms-heading pt-2'> Governing Law</h3>
            <p className='terms-content'>These terms and conditions shall be governed by and construed in accordance with the laws of India. Any disputes arising from this agreement shall be subject to the exclusive jurisdiction of the courts of India.</p>
           <br />
            <p className='terms-content'>By booking our packers and movers services, you signify your understanding and agreement to these terms and conditions. If you do not agree with any part of these terms, please refrain from using our services.</p>
            </div>
        </div>
     </div>
  )
}

export default TermAndCondition