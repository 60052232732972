import React, { useContext,lazy,Suspense, useEffect, useState } from 'react'
import { Routes,Route,Outlet, Navigate } from 'react-router-dom'
import './App.css'
import {GetEstimate1,GetEstimate2, OrderPlace, Rooms, Selectitem } from './estimatepages/Exportfiles'
import {Homepage,NavigationBar,Footer} from './homepage/Exportfile'
import { Signup,Signin, ForgetPassword, CheckEmail,MyOrders ,Otp,  Profile, Faq } from './pages/Exportfile'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import PresistLogin from './pages/PresistLogin'
import RequiredAuth from './pages/Requiredlogin'
import Requiredlogin from './pages/Requiredlogin'
import { AuthContext } from './AuthProvider'
import Fade from 'react-reveal/Fade';
import Service1  from './pages/servicepage/service1'
import FlatforRent from './pages/Flat/FlatforRent'
import ScrollToTop from './estimatepages/ScrollToTop'
import AboutUs from './pages/Aboutus/AboutUs'
import Career from './pages/Career/Career'
import 'lightbox.js-react/dist/index.css'
import Help from './pages/Help&Support/Help'
import Loader from './Loader'
import TrackOrder from './estimatepages/TrackOrder/TrackOrder'
import Gallery from './pages/Gallery/Gallery'
import Contactus from './pages/contactus/Contactus'
import DigitalContact from './pages/digialcontact/DigitalContact'
import Blogs from './pages/Blogs/Blogs'
import BlogsDetails from './pages/Blogs/BlogsDetails'
import ComponyPortal from './pages/signin/ComponyPortalLogin'
import NewFooter from './homepage/footer/newfooter/NewFooter'
import Toast from './Toast'
import axios from './axios'
//import ContactModel from './homepage/globalPopup/ContactModel'
import PrivacyPolicy from './homepage/privacypolicy/PrivacyPolicy'
import TermAndCondition from './homepage/termandcondition/TermAndCondition'
import RefundPolicy from './homepage/refundpolicy/RefundPolicy'
import CancellationPolicy from './homepage/cancellationpolicy/CancellationPolicy'
import ShippingPolicy from './homepage/shippingpolicy/ShippingPolicy'





const App = () => {
  const {userData} = useContext(AuthContext)
  const [citydata,setCityData]=useState([])
  const [isLoading,setIsLoading]=useState(false)

  const CityCardData =async () =>
  {
      try{
          setIsLoading(true)
          const response= await axios({
            method: "get",
           url:'/get_cities',
            
            } )
          
           if(response.status===200){
            const data = response?.data?.cities;
           // console.log("cityres", data);
             setCityData(data)
          }}
         catch(err){
          const error = err.response.data
          Toast(error.message);
          
       
       
         }
         finally{
          setIsLoading(false)
         }
       
      
  }
  

  useEffect(() => {
     
    CityCardData();
  },[]);
 

  
  return (
    <>
        {isLoading &&(<Loader/>)}
       <NavigationBar/> 
       
      <ScrollToTop />
    
      <Routes>
      <Route  element={<PresistLogin/>}> 
      <Route path='/' element={<Homepage/>}/>
      
      <Route path='/about-us' element={<AboutUs />}/>
      <Route path='/service/:service_id' element={<Service1 />}/>
      {citydata?.map((element) =>{

return    <Route path={`/packer-and-movers-in-${element?.slug}`} element={<FlatforRent id={element?.slug}/>}/>
})}
   
      <Route path='/faq' element={<Faq/>}/>
      <Route path='/privacy-policy' element={<PrivacyPolicy/>}/>
      <Route path='/gallery' element={<Gallery/>}/>
      <Route path='/Contact-us' element={<Contactus/>}/>
      <Route path='/career' element={<Career/>}/>
      <Route path='/terms-condition' element={<TermAndCondition/>} />
      <Route path='/Contact-us/global' element={<DigitalContact/>}/>
      <Route path='/refund-policy' element={<RefundPolicy/>}/>
      <Route path='/cancellation-policy' element={<CancellationPolicy/>}/>
      <Route path='/shipping-policy' element={<ShippingPolicy/>}/>
      <Route path='/blogs' element={<Blogs/>}/>
      <Route path='/blog/:id' element={<BlogsDetails />}/>
      
      <Route path='/signin' element={<Fade top><Signin/></Fade>}/>
      <Route path='/company-portal' element={<Fade top><ComponyPortal/></Fade>}/>
      <Route path='/otp' element={<Fade top><Otp/></Fade>}/>
      <Route path='/signup' element={userData?.is_active==1?<Navigate to='/'/>:<Fade top><Signup/></Fade>}/>
      <Route path='/help' element={<Help/>}/>
      <Route path='/track-order' element={<TrackOrder/>}/>
      
      <Route  element={<Requiredlogin/>}>
      
      <Route path='/profile' element={<Fade top><Profile/></Fade>}/>
      <Route path='/getestimate1' element={<Fade top><GetEstimate1/></Fade>}/>
      <Route path='/getestimate2' element={<Fade top><GetEstimate2/></Fade>}/>
      <Route path='/rooms' element={<Rooms/>}/>
      <Route path='/selectitem/:room_id' element={<Selectitem/>}/>
      <Route path='/order-place' element={<OrderPlace/>}/>
      <Route path='/myorders' element={<MyOrders/>}/>
   
   
      
      </Route>
      </Route>
      </Routes>
       <NewFooter/>
       {/* <Footer/>  */}
      <ToastContainer
position="top-right"
autoClose={2000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
{/* <ContactModel/>  */}
    </>

    
    
  )
}



export default App