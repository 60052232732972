import './ourtopcity.css'
//import CityCard from './utils/CityCard'
import CitySlider from './utils/CitySlider'
//import cityArr from './utils/cityArr'
function OurTopCity() {
  return (
     <div className="container-fluid py-4 bg-city" id="cityscroll">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <h5 className='text-center first-heading'>OUR TOP CITY</h5>
                    <h4 className='text-center pb-2'>Find Movers Near You</h4>
                </div>
            </div>
            <div className="row ">
            <div className="col-12 slider-bg">
                <CitySlider/>
            </div>
                        
            </div>
        </div>
     </div>
  )
}

export default OurTopCity