import { Link } from "react-router-dom"


function CityCard(cityprops) {
  
  return (
    <div className="py-3  d-flex justify-content-center align-items-center " key={cityprops.id}>
          <Link to={`/packer-and-movers-in-${cityprops?.cityAll?.slug}`} className="img-city m-1 m-lg-3 city-img-width d-flex justify-content-center align-items-center text-center">
             <img src={cityprops?.cityAll?.image} alt="no img"  width='80%' height='80%' className="city-img-width" />
          </Link>
          
          
    </div>
  )
}

export default CityCard