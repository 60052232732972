import React, { useEffect, useState } from "react";
import "./globalmodel.css";
import { Modal } from "react-bootstrap";
//import ContactForm from '../contact/changeContact/utils/ContactForm';
import ContactFormModel from "./ContactFormsModel";

function ContactModel() {
  const [show, setShow] = useState(false);
  //  const [contactFormData, setContactFormData] = useState("")
  useEffect(() => {
    const getToken = window.localStorage.getItem("userToken");
    //  console.log("token", getToken);
    setTimeout(() => {
      if (!getToken) {
        setShow(true);
      }
    }, 4000);
  }, []);

  const handleDataFromChild = (childData) => {
    // Do something with the data received from the child
    if (childData) {
      setShow(false);
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} className="modal-xl" centered>
        <Modal.Header closeButton>
          <Modal.Title
            className="text-center text-lg-start"
            style={{
              fontWeight: "bolder",
              fontSize: "18px",
              textTransform: "uppercase",
              color: "#0449c7",
            }}
          >
            FEEL FREE TO CONTACCT US!{" "}
            <p
              style={{ color: "#0d6efd" }}
              className="d-block d-lg-none text-center text-lg-start"
            >
              {" "}
              Get Best Quote For You
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="popoup-bg ">
          <div className="d-flex justify-content-end align-items-center">
            <ContactFormModel sendDataToParent={handleDataFromChild} />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ContactModel;
