//import { Toast } from 'bootstrap';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import axios from '../../../axios';
import { AuthContext } from '../../../AuthProvider';
import GroupA from '../../assest/GroupA.png'
import PrevB from '../../assest/PrevI.png'
import NextB from '../../assest/NextI.png'
import Toast from '../../../Toast';
function CustoerCarousel() {

    const {HomePage,setHomePage} = useContext(AuthContext)
    const {testimonial, image } = HomePage
    const [isLoading,setIsLoading]=useState(false)


    const testimonialfun =async () =>
    {
        try{
            setIsLoading(true)
            const response= await axios({
              method: "get",
             url:'/get_all_reviews',
              
              } )
             
             if(response.status===200){
              const data = response.data;
              console.log("data......",data);
              setHomePage((p)=>({...p,["testimonial"]:data?.reviews}))
              // Toast(data.message,response.status)
              
              
             }
            }
           catch(err){
            const error = err.response.data
            Toast(error.message);
            
         
         
           }
           finally{
            setIsLoading(false)
           }
         
    
    }
    
console.log("image", testimonial);

    useEffect(() => {
        !testimonial?.length ? testimonialfun():setIsLoading(false)

    },[])
    const ref = useRef(null);

  const onPrevClick = () => {
    ref.current.prev();
    console.log("fun", ref.current.prev());
  };
  const onNextClick = () => {
    ref.current.next();
  };
 
      // const data = [...testimonial, [avatar]:]
  return (
     <div className="container-fluid carousel-main-container carousel-main-container2 ">
         <div className="container">
        <Carousel  indicators={false} ref={ref} nextIcon={false} prevIcon={false} >
        {testimonial.map((customers)=>(
            <Carousel.Item key={customers?.id} interval={2000}>  
         <div className="container-fluid py-3">
         <div className="row">
           <div className="col-12 col-lg-5 d-flex justify-content-center">
                 <div className='carousel-img-container'>
                    <img src={`http://shopninja.in/anurag/budgetlogistics/public/storage/${customers?.image}`} alt="" className='img-fluid' />
                 </div>
           </div>
           <div className="col-12 col-lg-7 py-lg-4">
                 <div className="row ps-lg-4">
                    <div className="col-12 py-lg-2">
                       <h3 className='text-center text-lg-start'>{customers?.customer_name}</h3>
                    </div>
                    <div className="col-12 py-lg-2">
                        <p className='text-center text-lg-start'>{customers?.review}</p>
                    </div>

                 </div>
           </div>
         </div>
             
         </div>
      </Carousel.Item>
        ))}      
    </Carousel>
    
    </div>
    <div className=" col-12 ControlPrvNext d-flex justify-content-between">
    <button className='carousel-btn ms-lg-3' onClick={onPrevClick}>
       <img src={PrevB} alt="prev" />
       </button>
       <button className='carousel-btn me-lg-3' onClick={onNextClick}>
        <img src={NextB} alt="prev" />
       </button>
       </div>
    
     </div>
  )
}

export default CustoerCarousel