import React, { useContext, useEffect, useRef, useState } from 'react'
import Slider from "react-slick";
import CityCard from './CityCard';
//import cityArr from './cityArr';
import axios from '../../../axios';
//import { Toast } from 'bootstrap';
import Loader from '../../../Loader'
import { AuthContext } from '../../../AuthProvider';
import Toast from '../../../Toast';

function CitySlider() {
 
  
  const [citydata,setCityData]=useState([])
  const [isLoading,setIsLoading]=useState(false)

  const CityCardData =async () =>
  {
      try{
          setIsLoading(true)
          const response= await axios({
            method: "get",
           url:'/get_cities',
            
            } )
          
           if(response.status===200){
            const data = response?.data?.cities;
           // console.log("cityres", data);
             setCityData(data)
          }}
         catch(err){
          const error = err.response.data
          Toast(error.message);
          
       
       
         }
         finally{
          setIsLoading(false)
         }
       
      
  }
  //console.log("city", citydata);
//console.log("testimonial", testimonial);

  useEffect(() => {
     
    CityCardData();
  },[])




  const sliderRef = useRef();
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 2,
        autoplay: true,
        speed: 3000,
        autoplaySpeed: 900,
        cssEase: "linear",

        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };
  return (
    <>
    {isLoading &&(<Loader/>)}
         <div >
       
        <Slider {...settings} >
        {

          citydata.map((val)=>(
    <div key={val.id}>
    <CityCard   cityAll={val}/>
    
      
  </div>
))

}
        {/* {

            cityArr.map((val)=>(
                <div key={val.id}>
                <CityCard   cityAll={val}/>
                
                  
              </div>
            ))
           
        } */}
          
          
        </Slider>
      </div>

    </>
  )
}

export default CitySlider