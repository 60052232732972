import React from 'react'
import loaderImg from '../src/pages/assest/loader.png'

const Loader = () => {
  return (
    <div className="loader-budget">
        {/* <img src="images/loader.png"  alt='loading...'  /> */}
        <img src={loaderImg }  alt='loading...'  />
       
    </div>
  )
}

export default Loader