import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import App from './App';
//import reportWebVitals from './reportWebVitals';
import "bootstrap-icons/font/bootstrap-icons.css"; 
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { HashRouter } from 'react-router-dom';
import AuthProvider from './AuthProvider'
import ContactModel from './homepage/globalPopup/ContactModel';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
  {/* { let times = setTimeout(()=>{
   
  }, 2000)
  clearTimeOut(times)
  } */}
  <ContactModel/>
  <AuthProvider>
  <HashRouter>
 
    <App />
    </HashRouter>
    </AuthProvider>
    </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
