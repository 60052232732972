import "./newfooter.css";
import footerCopnayLogo from "../../assest/FooterCompanyLogo.png";
import WhatsappA from "../../assest/WhatsAppC.png";
import InstaA from "../../assest/InstaB.png";
import FacebookA from "../../assest/facebookA.png";
import call from "../../assest/call.png";
import message from "../../assest/message.png";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "../../../axios";
import Toast from "../../../Toast";
//import { Toast } from "bootstrap";

function NewFooter() {
  const [isLoading, setIsLoading] = useState(false);
  const [dropdown, setDropdown] = useState([]);
  const [subscribe, setSubscribe] = useState("");
  // dropdown
  const getservicefun = async () => {
    try {
      setIsLoading(true);
      const response = await axios({
        method: "get",
        url: "/get_services",
      });

      if (response.status === 200) {
        const data = response.data;
        setDropdown(data?.services);
        // Toast(data.message,response.status)
      }
    } catch (err) {
      const error = err.response.data;
      Toast(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getservicefun();
  }, []);
  // On subscribe btn in footer

  const onSubscribe = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      if (subscribe.length > 0) {
        const response = await axios({
          method: "post",
          url: "/subscribe",
          data: {
            email: subscribe,
          },
        });
        if (response.status === 200) {
          const data = response.data;
          Toast(data?.message, response.status);
          setSubscribe("");
        }
      }
    } catch (err) {
      const error = err.response.data;
      Toast(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  // const handleComplaint = ()=>{
  //   window.location.href='https://mail.google.com/mail/?view=cm&to=support@budgetlogisticspacker.com&su=Complaint';
  //   // window.location.href = 'mailto:support@budgetlogisticspacker.com?subject=Complaint';
  // }
  return (
    <div className="container-fluid fluid-bg-footer">
      <div className="container">
        <div className="row ">
          <div className="col-12 col-lg-6 py-2">
            <div className="img-footer py-3 ms-3 ps-3 ps-lg-0 ms-lg-0 text-center text-lg-start">
              <Link to="/">
                <img
                  src={footerCopnayLogo}
                  alt=""
                  className="footer-companyImg"
                  width="70%"
                  height="40%"
                />
              </Link>
            </div>
          </div>
          <div className="col-12 col-lg-6 d-flex flex-row justify-content-center align-items-center">
            <div className="row  ">
              <div className="col-12 col-lg-3">
                <p className="text-light  pt-2">Subscribe to our newsletter</p>
              </div>
              <div className="col-12 col-lg-9 d-flex flex-row justify-content-center align-items-center">
                <form className="f ">
                  <div className=" d-flex flex-row justify-content-center">
                    <input
                      type="email"
                      value={subscribe}
                      onChange={(e) => setSubscribe(e.target.value)}
                      placeholder="Enter Your Email"
                      className="form-control shadow subscribe-input"
                    />
                    <button
                      onClick={onSubscribe}
                      className="btn btn-light subscribe-btn shadow"
                    >
                      Subscribe
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="row py-3  ">
          <div className="col-6 ps-lg-2  col-lg-3 ">
          <h3 className="darkBlue text-center  text-lg-start footer-font py-3  pe-lg-0">
              Gurugram Branch Office
            </h3>
            <address className="white-text addres-width ms-lg-0 ps-lg-0 text-center  text-lg-start sub-font fw-lighter">
            Gaushala Rd, Block H, Ashok Vihar Phase III Extension, Gurugram, Haryana 122017, Contact Number: 9741793666
            </address>
            <h3 className="darkBlue text-center  text-lg-start footer-font py-3  pe-lg-0">
              Bangalore Branch Office
            </h3>
            <address className="white-text addres-width ms-lg-0 ps-lg-0 text-center  text-lg-start sub-font fw-lighter">
              B-44,1st floor, Dev Raj Urs Truck Terminal, opp. Kanteerva Studio,
              Yeshwantpur, Bangalore - 560022, Contact Number: 9916693666
            </address>
          
            <div className="col-12 d-flex flex-row justify-content-start">
              <a
                href="https://wa.me/9916693666"
                target="_blank"
                rel="noopener noreferrer"
                className="me-4"
              >
                <img src={WhatsappA} alt="" width="24px" />
              </a>
              <a
                href="https://www.facebook.com/profile.php?id=100089626541853"
                target="_blank"
                rel="noopener noreferrer"
                className=" me-4"
              >
                <img src={FacebookA} alt="" width="17px" />
              </a>
              <a
                href="https://www.instagram.com/budgetlogisticsandpackers/"
                target="_blank"
                rel="noopener noreferrer"
                className=" "
              >
                <img src={InstaA} alt="" width="24px" />
              </a>
            </div>
          </div>

          {/* quick contact */}
          <div className="col-6 ps-lg-2 col-lg-4">
            <div className="col-12">
              <h3 className="darkBlue text-start footer-font py-3 ">
                Quick Contact
              </h3>
              <div className="col-img py-lg-2 pe-lg-0 me-lg-0 d-flex flex-column flex-lg-row justify-content-center justify-content-lg-start align-items-lg-center">
                <img
                  src={call}
                  alt=""
                  width="20px"
                  height="20px"
                  className="ms-5 ms-lg-0"
                />
                <span className="ps-lg-2 d-none d-lg-block white-text">
                  +91 93-116-93-666
                </span>

                <p className="ps-lg-2 sub-font d-lg-none white-text">
                  +91 93-116-93-666
                </p>
              </div>
            </div>

            <div className="col-12">
              <div className="col-img py-lg-2 d-flex flex-column flex-lg-row justify-content-center justify-content-lg-start align-items-lg-center">
                <img
                  src={message}
                  alt=""
                  className="ms-5 ms-lg-0"
                  width="20px"
                  height="20px"
                />
                <span className="sub-font d-none d-lg-block ps-2 white-text">
                  support@budgetlogisticspackers.com
                </span>
              </div>
            </div>

            <div className="col-12 mt-5">
              <div className="col-img py-lg-2 pe-lg-0 me-lg-0 d-flex flex-column flex-lg-row justify-content-center justify-content-lg-start align-items-lg-center">
                <img
                  src={call}
                  alt=""
                  width="20px"
                  height="20px"
                  className="ms-5 ms-lg-0"
                />
                <span className="ps-lg-2 d-none d-lg-block white-text">
                  +91 88-087-00-087
                </span>

                <p className="ps-lg-2 sub-font d-lg-none white-text">
                  +91 93-116-93-666
                </p>
              </div>
            </div>
            <div className="col-12">
              <div className="col-img py-lg-2 d-flex flex-column flex-lg-row justify-content-center justify-content-lg-start align-items-lg-center">
                <img
                  src={message}
                  alt=""
                  className="ms-5 ms-lg-0"
                  width="20px"
                  height="20px"
                />
                <span className="sub-font d-none d-lg-block ps-2 white-text">
                  Info@budgetlogisticspackers.com
                </span>
              </div>
            </div>
          </div>

          {/* servicess */}
          <div className="col-6 col-lg-3 ps-lg-2 py-3 py-lg-0 text-light">
            <div className="col-12  ">
              <h3 className="darkBlue text-center text-lg-start footer-font py-3">
                Servicess
              </h3>
            </div>

            <div className="col-12 d-flex flex-column align-items-center align-items-lg-start justify-content-center">
              {dropdown.map((element) => (
                <>
                  <Link
                    className="link-padding sub-font white-text text-center text-lg-start"
                    to={"/service/" + element?.slug}
                  >
                    {element?.name}
                  </Link>
                </>
              ))}
            </div>
          </div>
          {/* company */}
          <div className="col-6 ps-lg-2 col-lg-2 py-3 py-lg-0 text-light">
            <div className="row">
              <div className="col-12 text-center">
                <h3 className="darkBlue text-lg-start text-center footer-font py-3 ">
                  Company
                </h3>
              </div>
            </div>
            <div className="col-12 d-flex flex-column align-items-center align-items-lg-start justify-content-center">
              <Link to="/" className="link-padding sub-font white-text">
                Home
              </Link>
              <Link to="/help" className="link-padding  sub-font white-text">
                Help
              </Link>
              <Link to="/about-us" className="link-padding sub-font white-text">
                About
              </Link>
              <Link to="/faq" className="link-padding sub-font white-text">
                FAQs
              </Link>
              <Link
                to="/privacy-policy"
                className="link-padding sub-font white-text"
              >
                Privacy Policy
              </Link>
              <Link
                to="/terms-condition"
                className="link-padding sub-font white-text"
              >
                Terms And Conditions
              </Link>
              <a
                href="mailto:support@budgetlogisticspacker.com?subject=Complaint"
                target="_blank"
                className="link-padding sub-font white-text"
              >
                Complain
              </a>

              {/* <Link to='/refund-policy'>
                <a
                  href="mailto:support@budgetlogisticspacker.com?subject=Complaint"
                  target="_blank"
                  className="link-padding sub-font white-text"
                >
                  Refund Policy
                </a>
              </Link> */}

<Link to="/refund-policy" className="link-padding  sub-font white-text">
                Refund Policy
              </Link>

              {/* <Link to='/cancellation-policy'>
                <a
                  href="mailto:support@budgetlogisticspacker.com?subject=Complaint"
                  target="_blank"
                  className="link-padding sub-font white-text mt-3"
                >
                  Cancellation Policy
                </a>
              </Link> */}

<Link to="/cancellation-policy" className="link-padding  sub-font white-text">
Cancellation Policy
              </Link>

              {/* <Link to='/shipping-policy'>
                <a
                  href="mailto:support@budgetlogisticspacker.com?subject=Complaint"
                  target="_blank"
                  className="link-padding sub-font white-text"
                >
                  Shipping Policy
                </a>
              </Link> */}

<Link to="/shipping-policy" className="link-padding  sub-font white-text">
Shipping Policy
              </Link>
            </div>
          </div>
        </div>
        <div className="row mt-1 mt-lg-3">
          <div className="col-12 col-lg-2">
            <p className="text-center white-text sub-font">Made in India</p>
          </div>
          <div className="col-12 col-lg-5">
            <p className="text-center white-text sub-font">
              Designed by :{" "}
              <a href="https://techninza.in" target="_blank">
                Techninza
              </a>{" "}
            </p>
          </div>
          <div className="col-12 col-lg-5">
            <p className="text-center white-text sub-font">
              {" "}
              © Copyright 2023 Budget Logistics and Packers{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewFooter;
